import React from "react";
import AWS from "aws-sdk";
import Button from "./button";

const PhotoGallery = ({photos, dataPhotos, onDelete}) => {

    const s3 = new AWS.S3({
        endpoint: "https://storage.yandexcloud.net", // Yandex Object Storage endpoint
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Замените на ваш access key
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Замените на ваш secret key
        region: "ru-central1", // Регион Yandex Cloud
        s3ForcePathStyle: true, // Для совместимости с Amazon S3
    });

    const handleDelete = async (photo) => {
        const params = {
            Bucket: "work-terminals.ru", // Имя вашего bucket
            Key: photo.Key, // Путь и имя файла
        };
        try {
            // Удаление объекта из Yandex Cloud
            await s3.deleteObject(params).promise();
            onDelete(photo.Location); // Удаление из состояния после успешного удаления из облака
        } catch (error) {
            console.error("Error deleting photo:", error);
        }
    };

    return (
        <div>
            {/*<div className='bg-secondary mt-2 rounded-1' style={{height: '10px', width: '100%'}}></div>*/}
            <div style={{display: "flex", flexWrap: "wrap"}} className='mt-2'>

                {photos.map((photo) => (
                        <div key={photo.Key} style={{margin: "10px"}} className='position-relative'>
                            <img
                                src={photo.Location}
                                alt={photo.Key}
                                style={{width: "150px", height: "150px", objectFit: "cover"}}

                            />
                            <div className='position-absolute top-0 end-0'>
                                <Button
                                    type='button'
                                    color='danger'
                                    size='btn-sm'
                                    rounded='rounded-1'
                                    icon={<i className='bi bi-trash'></i>}
                                    onClick={() => handleDelete(photo)}
                                />
                            </div>
                        </div>
                    )
                )}
            </div>
        </div>
    );
};

export default PhotoGallery;
