import React from "react";
import InfoCard from "../common/infoCard";
import {useSelector} from "react-redux";
import {getInfo, getInfoLoadingStatus} from "../../store/info";
import {Link} from "react-router-dom";
import Button from "../common/button";
import useTerminals from "../../hooks/useTerminals";

const InfoPage = () => {

    const info = useSelector(getInfo())
    const infoLoading = useSelector(getInfoLoadingStatus())
    const {history} = useTerminals()
    return (
        <div className=''>
            <div className='flex-column align-items-center'>
                    <Button
                        label='Назад'
                        type='button'
                        color='light'
                        onClick={() => history.goBack()}
                        icon={<i className='bi bi-arrow-left'></i>}
                        rounded='rounded-1'
                    />
                <div className='d-flex justify-content-center'>
                    <div className='d-grid w-100 mt-4 mb-4'>
                        <Link to={'/createInfo'} className='btn btn-light'>+</Link>
                    </div>
                </div>
                {!infoLoading && info.map(i => (
                    <InfoCard
                        key={i._id}
                        info={i.info}
                        id={i._id}
                        title={i.title}
                        urlImg={i.image}
                    />
                ))}
            </div>
        </div>
    )
}
export default InfoPage