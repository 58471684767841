import {Link} from "react-router-dom/cjs/react-router-dom.min"

const TableHeader = ({selectedMonth, searchQuery, filteredTerminal, setModalUpdatePrice}) => {

    const handleShowModal = () => {
        setModalUpdatePrice((prevState) => !prevState)
    }

    return (
        <thead>
        <tr>
            <th scope='col' className='check-box-col'></th>
            <th scope='col' className='col-1'>
                №
            </th>
            <th scope='col' className='col-2'>
                <div>
                    <Link
                        className='btn btn-light btn-sm d-flex justify-content-between'
                        to={'/createBody'}
                    >
                        <span>Корпус</span>
                        <span>+</span>
                    </Link>
                </div>
            </th>
            <th scope='col'>
                <Link
                    className='btn btn-light btn-sm d-flex justify-content-between'
                    to={'/createWorks'}
                >
                    <span>Доработки</span>
                    <span>+</span>
                </Link>
            </th>
            <th scope='col' className='col-1' colSpan='2'>
                <button
                    disabled={searchQuery || !selectedMonth || filteredTerminal.length === 0}
                    type='button'
                    className='btn btn-light btn-sm d-flex justify-content-between fw-bold'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Обновить цену терминалов в месяце'
                    onClick={handleShowModal}
                >
                    <span>Цена</span>
                </button>
            </th>
        </tr>
        </thead>
    )
}

export default TableHeader