export const dataMonth = [
    {
        value: 'TD0iZBHpk99mfGUVK6gth',
        label: 'Январь'
    },
    {
        value: 'UPuUzmBCMqUwCnu9b86Nh',
        label: 'Февраль'
    },
    {
        value: 'TgUvQdgAgj7fEsWtyDzIj',
        label: 'Март'
    },
    {
        value: 'bkjX7ipPaU6T3hPVL4ADl',
        label: 'Апрель'
    },
    {
        value: 'J0R5EOL20jwQ8d6QcKl9W',
        label: 'Май'
    },
    {
        value: 'NQOOTyrzioZ9yrNIUT1SZ',
        label: 'Июнь'
    },
    {
        value: 'BLl-aUksCyr1S10PNP9hL',
        label: 'Июль'
    },
    {
        value: 'bOUKheKGmvX_CWL0zOCGp',
        label: 'Август'
    },
    {
        value: 'liK533khotXOlYl5pyZr9',
        label: 'Сентябрь'
    },
    {
        value: '2-vHwBAbdB9hYUNBhXA6b',
        label: 'Октябрь'
    },
    {
        value: 'xf8OHoUuvEN5XTvkvWnkw',
        label: 'Ноябрь'
    },
    {
        value: '02HfQzAC0_x78Q2QJ7787',
        label: 'Декабрь'
    },
]

export const dataYear = [
    {
        value: 'l1Z1Pt1m9gGXe_hmx4EZu',
        label: '2022'
    },
    {
        value: 'l1Z0Pt1m9gGSe_hmx4EZy',
        label: '2023'
    },
    {
        value: 'ZZ6OrlUlKCqX38PJn9hmw',
        label: '2024'
    },
]