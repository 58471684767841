import React from "react";

const ProgressBar = ({value, countTerm}) => {

    const percentage = (value / countTerm) * 100

    if (!value) {
        return null
    }
    return (
        <div>
            <div className="progress mb-4" role="progressbar" aria-label="Example with label" aria-valuenow="25"
                 aria-valuemin="0" aria-valuemax="100">
                <div className="progress-bar" style={{width: percentage + '%', backgroundColor: 'var(--button-background-color)'}}><span>{Math.floor(percentage)}%</span></div>
            </div>
        </div>
    )
}
export default ProgressBar