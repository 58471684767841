import React from "react";
import TerminalsListPage from "../components/page/terminalsListPage";

const Terminals = () => {
    return (
        <>
            <TerminalsListPage/>
        </>
    );
};

export default Terminals;