import React, { useState } from "react";
import useTerminals from "../hooks/useTerminals";
import Button from "./common/button";
import { createWork } from "../store/works";
import TextField from "./inputs/textField";

const ModalCreateWork = ({hideWork}) => {

    const handleShow = () => {
        hideWork(false)
    }

    const [data, setData] = useState({
        name: '',
        sum: 0
    })

    const validatorConfig = {
        name: {
            isRequired: {
                message: "Это поле обязательно для заполнения"
            }
        },
        sum: {
            isRequired: {
                message: "Это поле обязательно для заполнения"
            }
        },
    };

    const {dispatch, handleChange, isValid, validate, errors} = useTerminals(data, setData, validatorConfig)

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValid = validate();
        if (!isValid) return;
        dispatch(createWork({...data, sum: Number(data.sum)}));
        setData({})
        handleShow()
    };

    return (
        <div className="modal show fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
             tabIndex="-1"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Добавить доработку</h1>
                        <Button
                            type="button"
                            color="light"
                            onClick={handleShow}
                            icon={<i className="bi bi-x-lg"></i>}
                            rounded="rounded-1"
                        />
                    </div>
                    <div className="p-2">
                <form onSubmit={handleSubmit}>
                    <TextField
                        label={'Новая доработка'}
                        name='name'
                        value={data.name}
                        onChange={handleChange}
                        error={errors.name}
                    />
                    <TextField
                        label={'Стиомость доработки'}
                        type='number'
                        name='sum'
                        value={data.sum}
                        onChange={handleChange}
                        error={errors.sum}
                    />
                </form>
                    </div>
                    <div className="modal-footer justify-content-start">
                        <Button
                            type="submit"
                            color="light"
                            rounded="rounded-1"
                            label="Добавить"
                            disabled={!isValid}
                            onClick={handleSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCreateWork