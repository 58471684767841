import React, {useState} from "react";
import AWS from "aws-sdk";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_FILES = 10;
const ALLOWED_FILE_TYPES = ["image/jpeg", "image/png", "image/gif"]; // допустимые типы

const PhotoUploader = ({onUploadSuccess, onFileUrlsChange, month, numberTerminal}) => {
        const [files, setFiles] = useState([]);
        const [isUploading, setIsUploading] = useState(false);
        const [fileUrls, setFileUrls] = useState([]);
        const [errorMessage, setErrorMessage] = useState("");

        const handleFileChange = (e) => {
            const selectedFiles = Array.from(e.target.files);

            // Проверка количества файлов
            if (selectedFiles.length > MAX_FILES) {
                setErrorMessage(`Вы можете загрузить не больше ${MAX_FILES} файлов за раз.`);
                return;
            }

            // Проверка типов файлов и размера
            for (const file of selectedFiles) {
                if (!ALLOWED_FILE_TYPES.includes(file.type)) {
                    setErrorMessage("Можно загружать только изображения (jpg, png, gif).");
                    return;
                }
                if (file.size > MAX_FILE_SIZE) {
                    setErrorMessage(`Размер файла ${file.name} превышает 5MB.`);
                    return;
                }
            }

            // Если все проверки пройдены
            setErrorMessage(""); // Сброс ошибки
            setFiles(selectedFiles);
        };

        const handleUpload = async () => {
            if (files.length === 0) {
                setErrorMessage("Выберите хотя бы один файл для загрузки.");
                return;
            }

            setIsUploading(true);

            // Настройка клиента AWS SDK для работы с Yandex Cloud
            const s3 = new AWS.S3({
                endpoint: "https://storage.yandexcloud.net", // Yandex Object Storage endpoint
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Замените на ваш access key
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Замените на ваш secret key
                region: "ru-central1", // Регион Yandex Cloud
                s3ForcePathStyle: true, // Для совместимости с Amazon S3
            });

            // Загрузка файлов
            const uploadPromises = files.map((file) => {
                const params = {
                    Bucket: "work-terminals.ru", // Имя вашего bucket
                    Key: `photos/terminals/${month}/${numberTerminal}/${file.name}`, // Путь и имя файла
                    Body: file,
                    ACL: "public-read", // Доступ к файлам
                };

                return s3.upload(params).promise();
            });

            try {
                const uploadedFiles = await Promise.all(uploadPromises);
                onUploadSuccess(uploadedFiles); // Вызов колбэка с информацией о загруженных файлах
                const imgUrls = uploadedFiles.map(file => file.Location)
                setFileUrls(imgUrls);
                onFileUrlsChange(imgUrls)
                setFiles([]);
            } catch (error) {
                console.error("Error uploading files:", error);
            }

            setIsUploading(false);
        };

        return (
            <div>
                <h5>0 / 10 Файлов загружено</h5>
                <input type="file" multiple onChange={handleFileChange} />
                <button onClick={handleUpload} disabled={isUploading || !files.length}>
                    {isUploading ? "Uploading..." : "Upload Photos"}
                </button>
                {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            </div>
        );
    }
;

export default PhotoUploader;
