import {Link} from "react-router-dom/cjs/react-router-dom.min"
import Button from "../../common/button"
import history from "../../../utils/history"
import {sumPrice} from "../../../utils/sumPrice"
const TableFooter = ({filteredExtraWorks, filteredTerminal, selectedMonth}) => {

    const handleClickExtraWorks = (id) => {
        history.push(history.location.pathname + `${id}/editExtraWorks`)
    }

    //--- Кол-во терминалов и сумма всех терминалов и доработок---
    const terminalCount = filteredTerminal.length

    const filterSumAllPrice = filteredTerminal.map(s => s.sum)
    const filterSumAllPriceExtraWorks = filteredExtraWorks.map(e => e.sum)
    const sumAllTermPrice = sumPrice(filterSumAllPrice)
    const allExtraWorkSumPrice = sumPrice(filterSumAllPriceExtraWorks)

    const percent = 5
    const worksSumPrice = filteredExtraWorks.length > 0 ? sumAllTermPrice + (selectedMonth === null ? allExtraWorkSumPrice : Number(filteredExtraWorks[0].sum)) : sumAllTermPrice + 0
    const result = worksSumPrice / 100 * percent
    //--------------------------------------------------

    return (
        <tfoot>
        <tr>
            <th scope='row' colSpan='6'>
                <div className='d-grid mx-auto'>
                    {selectedMonth && !filteredExtraWorks.length > 0 ? (
                        <Link
                            to={'/createExtraWorks'}
                            className='d-flex justify-content-between btn btn-light'
                        >
                            <div>Доп. работы:</div>
                            <div>+</div>
                        </Link>
                    ) : (
                        <div>
                            <span>Доп. работы:</span>
                        </div>
                    )}
                </div>
            </th>
        </tr>
        {filteredExtraWorks.length > 0 &&
            filteredExtraWorks.map(e => (
                <tr key={e._id}>
                    <th scope='row' colSpan='4'>
                        <div
                            className='fw-semibold'
                            style={{whiteSpace: 'pre-wrap'}}
                        >
                            <p>{e.extraWorks}</p>
                        </div>
                    </th>
                    <th colSpan='1'>{e.sum}</th>
                    <th className='text-end'>
                        <Button
                            type='button'
                            color='light'
                            size='btn-sm'
                            rounded='rounded-1'
                            icon={<i className='bi bi-pencil-square'></i>}
                            onClick={() => handleClickExtraWorks(e._id)}
                        />
                    </th>
                </tr>
            ))}
        <>
            <tr>
                <th scope='row' colSpan='2'>
                    Итого:
                </th>
                <th>{terminalCount}</th>
                <th colSpan='3' className='text-end'>
                    {sumAllTermPrice} + {allExtraWorkSumPrice} ={' '}
                    {sumAllTermPrice + allExtraWorkSumPrice} - 5%(
                    {result}) = {worksSumPrice - result}
                </th>
            </tr>
            {selectedMonth && (
                <tr>
                    <th scope='row' colSpan='6'>
                        <div className='d-grid mx-auto'>
                            <Link to={'/createTerminal'} className='btn btn-light'>
                                +
                            </Link>
                        </div>
                    </th>
                </tr>
            )}
        </>
        </tfoot>
    )
}

export default TableFooter