import useTerminals from "../../../hooks/useTerminals"
import Body from "../body"
import Works from "../works"
import history from "../../../utils/history"
import Button from "../../common/button"
import React, {useRef, useState} from "react"
import CheckBox from "../../checkbox";

const TableBody = ({filteredTerminal, searchQuery, setOrderId, btnOn, setModalDeleteOrderTerm}) => {

    const handleClick = (id) => {
        history.push(history.location.pathname + `${id}/editTerminal`)
    }

    const handleClickInfoTerminal = (id) => {
        history.push(history.location.pathname + `${id}/terminalInfo`)
    }

    const handleShowModalDeleteOrder = (orderId) => {
        setModalDeleteOrderTerm((prevState) => !prevState)
        setOrderId(orderId)
    }
    const {setting, dispatch} = useTerminals()

//--------------------------------------------------------------------
    const [checkedRows, setCheckedRows] = useState([]);
    const [isSelecting, setIsSelecting] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [startIndex, setStartIndex] = useState(null);
    const tableRef = useRef(null);

    const handleMouseDown = (index, e) => {
        e.preventDefault(); // Отключает стандартное выделение текста при нажатии
        setIsSelecting(true);
        setStartIndex(index); // Сохраняем индекс первой строки
        toggleCheckbox(index);
        setLastSelectedIndex(index);
    };

    const handleMouseUp = () => {
        setIsSelecting(false);
        setLastSelectedIndex(null);
    };

    const handleMouseEnter = (index, id) => {
        if (isSelecting) {
            if (index > lastSelectedIndex) {
                // Двигаемся вниз
                toggleCheckbox(index, id);
            } else if (index < lastSelectedIndex && index >= startIndex) {
                // Двигаемся вверх
                toggleCheckbox(lastSelectedIndex, id);
            }
            setLastSelectedIndex(index);
        }
    };

    const toggleCheckbox = (index, id) => {
        setCheckedRows((prevCheckedRows) => {
            const isChecked = prevCheckedRows.includes(index);
            if (isChecked) {
                // Если чекбокс уже отмечен, снимаем галочку
                return prevCheckedRows.filter((i) => i !== index);
            } else {
                // Если чекбокс не отмечен, добавляем галочку
                return [...new Set([...prevCheckedRows, index])];
            }
        });
    };

    return (
        <tbody ref={tableRef} onMouseUp={handleMouseUp}>
        {filteredTerminal.length > 0 &&
            filteredTerminal.map((t, index) => (
                <tr key={t._id}
                    // onMouseDown={(e) => handleMouseDown(index, e)}
                    // onMouseUp={handleMouseUp}
                    // onMouseEnter={() => handleMouseEnter(index)}
                    // className={checkedRows.includes(index) ? 'bg-secondary' : ''}
                    // style={{userSelect: 'none'}} // Отключение выделения текста
                >
                    <td className='td-check-box'
                        // onMouseDown={(e) => handleMouseDown(index, e)}
                        // onMouseUp={handleMouseUp}
                        // onMouseEnter={() => handleMouseEnter(index)}
                    >
                        <CheckBox
                            // terminals={checkboxes}
                            terminals={filteredTerminal}
                            tid={t._id}
                            check={t.check}
                            // check={!!selectedRows[index]}
                        />
                        {/*<input*/}
                        {/*    // defaultChecked={check}*/}
                        {/*    className='form-check-input'*/}
                        {/*    type="checkbox"*/}
                        {/*    checked={checkedRows.includes(index)}*/}
                        {/*    readOnly*/}
                        {/*/>*/}
                    </td>
                    <td key={t._id}>
                        <p className='m-0 p-0 fw-bold'>
                            <Button
                                label={t.number}
                                type='button'
                                color='light'
                                size='btn-sm'
                                rounded='rounded-1'
                                onClick={() => handleClickInfoTerminal(t._id)}
                            />
                            {/*{t.number}*/}
                        </p>
                    </td>

                    <td>
                        {searchQuery && (
                            <span
                                className='fst-italic fw-light'
                                style={{fontSize: 12}}
                            >
													{t.month.label} {t.year.label}
												</span>
                        )}
                        <Body id={t.body}/>
                    </td>
                    <td>
                        <div>
                            <Works
                                city={t.city}
                                works={t.works}
                                sumTerminal={t.sum}
                                bodyTerminal={t.body}
                                setting={setting}
                            />
                        </div>
                    </td>
                    <td>
                        <p className='m-0 p-0'>{t.sum}</p>
                    </td>
                    <td>
                        <div className='d-flex justify-content-end'>
                            <div className='d-flex'>
                                <Button
                                    type='button'
                                    color='light'
                                    size='btn-sm'
                                    rounded='rounded-1'
                                    icon={<i className='bi bi-pencil-square'></i>}
                                    onClick={() => handleClick(t._id)}
                                />
                                {!btnOn && t.singleOrder && (
                                    <div className='ms-1'>
                                        <Button
                                            type='button'
                                            color='danger'
                                            size='btn-sm'
                                            rounded='rounded-1'
                                            icon={<i className='bi bi-trash'></i>}
                                            onClick={() => handleShowModalDeleteOrder(t.singleOrder)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
        </tbody>
    )
}

export default TableBody