import React from "react";
import Button from "./button";
import history from "../../utils/history";
import ContainerFormWrapper from "./containerForm";

const InfoCard = ({urlImg, title, info, id}) => {

    const handleClickInfo = (id) => {
        history.push(`${id}/editInfo`)
    }

    return (
        <ContainerFormWrapper>
            <div className='d-flex position-relative'>
                <div className='opacity-50 position-absolute end-0'>
                    <Button
                        color='secondary'
                        rounded='rounded-1'
                        icon={<i className="bi bi-pencil-square"></i>}
                        onClick={() => handleClickInfo(id)}
                    />
                </div>
                <img src={urlImg ? urlImg : ''} alt={urlImg ? '' : urlImg}
                     className='rounded-1 img-fluid me-4' style={{width: 20+'vh'}}/>
                <div>
                    <h5 className='mt-2'>{title}</h5>
                    <hr/>
                    <p style={{whiteSpace: 'pre-wrap'}}>{info}</p>
                </div>
            </div>
        </ContainerFormWrapper>
    )
}
export default InfoCard