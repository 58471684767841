export function sumPrice(arr) {
    return arr.reduce((acc, curr) => {
        return acc + Number(curr)
    }, 0)
}

export function autoSumString(str) {
    const reg = /[=][\d\s]+?руб./g
    const stringSumm = str && str.match(reg)
    const findNumber = stringSumm ? sumPrice(stringSumm.map(el => el.substring(2, el.length - 5))) : ''
    return findNumber
}