import React, {useState} from 'react';
import AWS from 'aws-sdk';
import Button from "./button";

// Настройте AWS SDK с параметрами Yandex Object Storage
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY, // Замените на ваш access key
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY, // Замените на ваш secret key
    region: process.env.REACT_APP_AWS_REGION, // Регион Yandex Object Storage
    endpoint: new AWS.Endpoint('storage.yandexcloud.net'),
    s3ForcePathStyle: true,
});
const s3 = new AWS.S3();

const ImageUpload = ({onFileUrlsChange, path}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileUrls, setFileUrls] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const imageFiles = files.filter(file => file.type.startsWith('image/'));
        setSelectedFiles(imageFiles);
    };

    const handleUpload = () => {
        if (selectedFiles.length === 0) {
            alert('Пожалуйста, выберите хотя бы один файл для загрузки.');
            return;
        }

        const uploadPromises = selectedFiles.map(file => {
            const params = {
                Bucket: 'work-terminals.ru', // Замените на название вашего бакета
                Key: `${path}/${file.name}`, // Загрузка файла в папку photos
                Body: file,
                ACL: 'public-read', // Сделайте объект общедоступным, если это необходимо
            };

            return s3.upload(params).promise();
        });

        Promise.all(uploadPromises)
            .then(results => {
                const urls = results.map(result => result.Location);
                console.log('Загруженные URL:', urls);
                setFileUrls(urls);
                onFileUrlsChange(urls); // Передача fileUrls в родительский компонент
                alert('Все файлы успешно загружены.');
            })
            .catch(err => {
                console.error('Ошибка загрузки:', err);
                alert('Ошибка загрузки.');
            });
    };

    return (
        <div>
            <div className='d-flex flex-column'>
                <input className='mb-2' type="file" multiple onChange={handleFileChange}/>
                <Button
                    label='Загрузить'
                    color='light'
                    rounded='rounded-1'
                    onClick={handleUpload}
                />
            </div>
            {fileUrls.length > 0 && (
                <div>
                    <h3>Загруженные файлы:</h3>
                    {fileUrls.map((url, index) => (
                        <div key={index}>
                            <div>
                                <img src={url} alt={`Uploaded ${index + 1}`}
                                     style={{marginTop: '10px', maxWidth: '25%'}}/>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ImageUpload;
